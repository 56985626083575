.hits-mob p {
    margin: auto;
    padding-bottom: 20px;
    margin-bottom: 50px;
}

.hits-img {
    width: 250px;
    height: 250px;
}

.custom-arrow-products {
    width: 30px;
    height: 30px;
    background: radial-gradient(70.74% 100.65% at 50% 50%, rgb(244, 192, 79) 0%, rgb(239, 166, 6) 100%);
    padding: 15px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    z-index: 2;
    position: absolute;
    top: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@supports (-webkit-touch-callout: none) {
    .custom-arrow-products {
        top: 75%;
    }
}

.left{
    left:10px;
}

.right{
    right: 10px;
}

.hits {
    max-width: 1040px;
    height: 850px;
    margin: 0 auto;
    padding: 60px 20px;
    text-align: center;
    position: relative;
}

.hits-img-container {
    position: relative;
    text-align: center;
  }
  
  .image-wrapper {
    position: relative;
  }
  
  .product-name {
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
    color: white; 
    background-color: #F1AC17;
    padding: 10px 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-size: 20px;
    z-index: 2; 
  }

.hits::before{
    content: '';
    position: absolute;
    width: 1440px;
    height: 1050px;
    top: -10%;
    left: -15%;
    background-image: url("./../../../public/images/system/bg-hits.png");
    z-index: -1;
}

.hits h2 {
    font-size: 32px;
    margin-bottom: 20px;
    font-weight: bold;
}

.hits p {
    margin: auto;
    margin-bottom: 40px;
    width: 270px;
}

.hits .products {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    max-width: 100%;
}

.hits .product {
    position: absolute;
    border-radius: 20px;
    width: 290px;
    height: 290px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.hits .product img {
    position: relative;
    display: block;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.product img:hover {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    cursor: pointer;
    filter: brightness(50%); 
}

.hits .product:nth-child(1) {
    top: 0px;
}

.hits .product:nth-child(2) {
    top: 150px;
    left: 5%;
    transform: translateX(-10%);
}

.hits .product:nth-child(3) {
    top: 150px;
    right: 5%;
    transform: translateX(10%);
}

.hits .product:nth-child(4) {
    top: 320px;
}

.SliderProductsItems {
    display: none;
}

.slick-slide img {
    display: inline;
}

.buy{
    margin: 20px auto;
}

@media (max-width: 768px) {
    .hits{
        margin-top: 50px;
        max-width: 650px;
        height: 650px;
    }

    .hits::before{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: -10%;
        left: 0;
        background-image: url("./../../../public/images/system/bg-hits-mob.png");
        background-repeat: repeat;
        background-size: 250px 280px; 
        background-position: center;
        z-index: -1;
    }
    
    @supports (-webkit-touch-callout: none) {
        .hits {
            margin-top: 100px;
        }
    }

    .SliderProductsItems {
        display: block;
        margin-top: 180px;
    }

    .hits-container {
        display: none;
    }

    .hits p {
        width: 222px;
    }
    
}

@media (min-width: 768px) and (max-width: 980px) {

    .hits{
        max-width: 650px;
        height: 700px;
    }
    .hits .product {
        width: 200px;
        height: 200px;
    }
}