.breadcrumbs {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 16px;
    color: #F0A707; 
  }

  .breadcrumb-item {
    display: inline;
    color: #F0A707; 
  }
  
  .breadcrumb-item a {
    color: #EDCB81;
    text-decoration: none;
    font-weight: bold;
  }
  
  .breadcrumb-item a:hover {
    text-decoration: underline; 
  }
  
  .breadcrumb-item.active a {
    color: #F0A707;
  }
  
  .breadcrumb-item:last-child::after {
    content: '';
  }

  @media (max-width: 1480px){
    .breadcrumbs{
      margin-left: 0px;
    }
  }

  @media (min-width: 980) and (max-width: 1440px){
    .breadcrumbs{
      margin-left: 0px;
    }
  }