.img-container {
  width: 200px;
  margin: 0 10px 50px 10px;
  float: left;
}

.img {
  width: 100%;
  border-radius: 30px;
  border: 8px solid #F1AC17 ;
  transition: transform 0.5s ease;
  width: calc(100% - 30px);
  cursor: pointer;
}
  
.slider-container {
  max-width: 960px;
  position: relative;
  margin: 0 auto;
}

.custom-arrow {
  width: 30px;
  height: 30px;
  background: radial-gradient(70.74% 100.65% at 50% 50%, rgb(244, 192, 79) 0%, rgb(239, 166, 6) 100%);
  padding: 15px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  z-index: 2;
  position: absolute;
  top: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-btn-pulsation {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  position: absolute;
  left: -5px;
  top: -5px;
  z-index: -1;
  transform: scale(0);
  animation: pulsation 2s infinite;
  background: radial-gradient(70.74% 100.65% at 50% 50%, rgb(244, 192, 79) 0%, rgb(239, 166, 6) 100%);;
}


@keyframes pulsation {
  0% {
      -ms-transform: scale(0);
      -webkit-transform: scale(0);
      transform: scale(0);
      opacity: 1
  }

  50% {
      opacity: .5
  }

  to {
      -ms-transform: scale(1);
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 0
  }
}

.prev {
  left: -35px;
}

.next {
  right: -35px; 
}


.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  cursor: pointer;
}


.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: auto;
  max-height: 600px;
  z-index: 1001;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
}

.modal-content img {
  max-width: auto;
  max-width: 90vw;
  max-height: 600px;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}