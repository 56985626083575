.imgContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-bottom: 50px;
}

.imgContainerItem {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 800px;
  margin-right: 10px;
}

.imgContainerItem img {
  width: 390px;
  height: 200px;
  margin-bottom: 20px;
  border-radius: 20px;
  object-fit: cover;
}

.imgContainerItem img:hover{
  cursor: pointer;
}

.sideContainer {
  margin-left: 10px;
}

.sideContainer img {
  width: 400px;
  height: 420px;
  border-radius: 20px;
  object-fit: cover;
}

.sideContainer img:hover{
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: auto;
  max-height: 600px;
  z-index: 1001;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
}

.modal-content img {
  max-width: auto;
  max-width: 90vw;
  max-height: 600px;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

@media (max-width: 769px) {
  .imgContainer{
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  .imgContainerItem {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 360px;
    margin-left: 10px;
  }
  
  .imgContainerItem img {
    width: 175px;
    height: 120px;
    margin-bottom: 10px;
    border-radius: 20px;
  }

  .sideContainer img {
    width: 360px;
    height: 220px;
    border-radius: 20px;
    margin-right: 10px;
  }
}

@media (min-width: 769px) and (max-width: 980px) {
  .imgContainerItem {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 420px;
    margin-right: 10px;
  }
  
  .imgContainerItem img {
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
    border-radius: 20px;
  }

  .sideContainer img {
    width: 300px;
    height: 420px;
    border-radius: 20px;
  }
}

@media (min-width: 980px) and (max-width: 1220px) {
  .imgContainerItem {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 620px;
    margin-right: 10px;
  }
  
  .imgContainerItem img {
    width: 300px;
    height: 200px;
    margin-bottom: 20px;
    border-radius: 20px;
  }

  .sideContainer img {
    width: 300px;
    height: 420px;
    border-radius: 20px;
  }
}
