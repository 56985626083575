@font-face {
    font-family: 'SITKAVF';
    src: url('./../fonts/fonts/SITKAVF.ttf') format('woff');
    font-weight: 300;
}

@font-face {
    font-family: 'SITKAVF-ITALIC';
    src: url('./../fonts/fonts/SITKAVF-ITALIC.ttf') format('woff');
    font-weight: 300;
}


body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
  }

  

.container {
    max-width: 1440px;
    margin: 0 auto;
    padding: 20px;
    margin-bottom: 224px;
    position: relative;
    z-index: 1;
    overflow: visible;
}

.container::before{
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    max-width: 1600px;
    height: 1000px;
    transform: translateX(-50%); 
    background-repeat: no-repeat;
    background-size: 103%;
    background-image: url("./../../public/images/system/bg-product-page.png");
    z-index: -1;
}

.gen-image{
    width: 444px;
    height: 444px;
    border-radius: 10px;
}


.product-page {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.product-gallery {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 20px;
    padding-left: 30px;
    margin-top: 30px;
}

.product-gallery .main-image {
    border-radius: 10px;
}

.thumbnails-wrapper{
    margin: 0 auto
}

.thumbnails img {
    width: 140px;
    height: 126px;
    border-radius: 10px;
    margin: 5px;
    cursor: pointer;
}

.product-details {
    flex: 1;
    padding-left: 30px;
}

.product-details-item {
    margin-bottom: 20px;
    line-height: 1.5;
}

.product-details-last-item {
    margin-bottom: 60px;
}

.product-details ul {
    list-style-type: none;
    padding: 0;
}

.product-details ul li {
    margin-bottom: 5px;
}

.cta-button {
    display: inline-block;
    background-color: #ffca28;
    color: #fff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    margin-top: 20px;
}

.cta-button:hover {
    background-color: #ffb300;
}

.main-image-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-custom-arrow {
    width: 30px;
    height: 30px;
    background: radial-gradient(70.74% 100.65% at 50% 50%, rgb(244, 192, 79) 0%, rgb(239, 166, 6) 100%);
    padding: 0;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    z-index: 2;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.prec{
    left: -40px;
}

.follow{
    right: -40px;
}

.product-page{
    display: flex;
    flex-direction: column;
}

.product-content{
    display: flex;
}

@media (max-width: 980px){

    .product-details {
        position: relative;
        padding-left: 0;
    }

    .product-content{
        display: flex;
        flex-direction: column;
    }
    
    .container::before{
        content: '';
    }

    .header-honey{
        position: absolute;
        top: 15%;
        left: 25%;
    }

    .product-gallery{
        margin: 0 auto;
    }

    .product-page{
        display: flex;
        flex-direction: column;
        margin: auto;
    }

    .gen-image{
        width: 252px;
        height: 252px;
        border-radius: 10px;
    }
    
    .thumbnails img {
        width: 80px;
        height: 72px;
        border-radius: 10px;
        margin: 3px
    }
}