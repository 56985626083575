.toggle-img{
    display: none;
}

.bur-container{
    display: none;
}

@media (max-width: 768px) {

    .header{
        height: 80px;
    }

    .href-logo{
        display: flex;
        align-items: center;
        width: 157px;
        height: 80px;
        margin: auto;
    }

    .toggle-img {
        display: block;
        width: 157px;
        height: 56px;
    }

    .bur-container {
        position: relative;
        display: inline-block;
        cursor: pointer;
    }
    
    .bar-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: absolute;
        top: -70px;
        left: 30px;
        width: 30px;
        height: 30px;
    }

    .mob-menu-items {
        display: none;
        box-sizing: border-box;
        position: absolute;
        left: 0;
        top: -15px;
        background-color: #FCF5E5;
        z-index: 999;
        padding: 20px;
    }

    .bg-mob-menu{
        width: 150px;
        height: auto;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1
    }

    .mob-menu-items.active {
        display: flex;
        flex-direction: column;
        width: 100vw;
    }

    .mob-info{
        min-width: 355px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        line-height: 35px;
    }

    .mob-info a{
        max-width: 245px;
        font-size: 20px;
    }

    .mob-menu-p{
        max-width: 245px;
    }

    .menu-frame{
        width: 320px;
        height: 230px;
        box-sizing: border-box;
    }

    .mob-menu-h4{
        max-width: 245px;
    }
}
