@font-face {
    font-family: 'SITKAVF';
    src: url('./../fonts/fonts/SITKAVF.ttf') format('woff');
    font-weight: 300;
}

@font-face {
    font-family: 'SITKAVF-ITALIC';
    src: url('./../fonts/fonts/SITKAVF-ITALIC.ttf') format('woff');
    font-weight: 300;
}

html {
    scroll-behavior: smooth;
}
  
.body {
    Overflow-x: hidden;
    margin: auto;
    font-family: 'SITKAVF', sans-serif;
}


.f-13-l-40 {
    font-size: 13px;
    line-height: 40px;
}

.f-13-l-40 {
    font-size: 15px;
    line-height: 40px;
}

.f-40 {
    font-size: 40px;
    line-height: 40px;
}

.f-25 {
    font-size: 25px;
    line-height: 40px;
}

.f-15 {
    font-size: 15px;
    line-height: 20px;
}

.f-13-l-20 {
    font-size: 13px;
    line-height: 20px;
}

.t-color-C68800{
    color: #C68800;
}

.t-color-0{
    color: #000000;
}

.t-color-FFF{
    color: #FFFFFF;
}

a{
    text-decoration: none;
    color: #C68800;
}

.about-us-bg{
    position: relative;
    overflow: hidden;
}

.logo{
    width: 157px;
    height: 56px;
    margin-left: 10px;
}

.header{
    background-color: #FFFFFF;
    position: sticky !important;
    top: 0;
    z-index: 999;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .05);
}
.menu-items{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    max-width: 1440px;
    margin: 0 auto;
}

.menu-items div{
    margin: auto;
}

.honey-header{
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    height: 450px;
    margin-bottom: 50px;
    max-width: 1440px;
    margin: 0 auto;
    background-color: #FCF5E5;
}


.honey-header-div{
    width: 50%;
}

.honey-header-h3{
    max-width: 320px;
    background-color: #FFF1D0;
    border-radius: 15px;
    padding: 0 14px;
    text-wrap: nowrap;
    text-align: center;
    line-height: 25px;
}

.about-us{
    display: flex;
    justify-content: space-around;
    padding-bottom: 50px;
    height: 600px;
    max-width: 1440px;
    margin: 0 auto;
}

.about-us::before{
    content: '';
    position: absolute;
    width: 1440px;
    height: 650px;
    background-image: url("./../../public/images/system/background_header_2.png");
    background-repeat: no-repeat;
    background-size: 103%;
    z-index: -1;
    top: -20%;
}

.about-us-item{
    width: 40%;
    height: auto;
    margin: auto;
}

.about-us-img{
    margin: auto;
}

.ukranian-honey{
    display: flex;
    flex-direction: column;
    height: 600px;
    max-width: 1440px;
    margin: 0 auto;
}

.ukranian-honey-header{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ukranian-honey-item{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.ukranian-honey-p{
    width: 50%;
    margin: auto;
}

.ukranian-honey-img{
    margin: auto;
}

.our-bees-and-hives-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 120px;
    max-width: 1440px;
    margin: 60px auto;
}

.our-bees-and-hives-container-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.our-bees-and-hives-item{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.our-bees-and-hives-item div{
    width: 40%;
    margin: auto;
}

.our-bees-and-hives-item img{
    margin: auto;
}

.our-service-container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    max-width: 1440px;
    margin: 0 auto;
    margin-bottom: 120px;
    margin-top: 120px;
    position: relative; 
}

.our-service-container::before{
    content: '';
    position: absolute;
    width: 1440px;
    height: 800px;
    left: 0;
    background-image: url("./../../public/images/system/bg-our-service.png");
    z-index: -1;
    background-repeat: no-repeat;
    margin: auto;
}

.our-service-pic{
    margin: auto;
    max-width: 550px;
}

.our-service{
    width: 40%;
    margin: auto;
}

.our-production-container{
    display: flex;
    flex-direction: column;
}

.our-production-container-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.medove-form {
    display: flex;
    position: relative;
    margin: auto;
    margin-bottom: 50px;
    width: calc(960px - 128px);
    height: calc(320px - 96px);
    background: radial-gradient(circle at center,#f4c04f,#efa606);
    padding: 48px 64px;
    border-radius: 60px;
    overflow: hidden;
}

.medove-form-text {
    width: 462px;
    height: 128px;
    margin-bottom: 50px;
    margin-top: -30px;
}

.medove-form-left-part {
    align-content: end;
    z-index: 1;
}

.medove-inputs {
    width: calc(195px - 30px);
    height: calc(55px -30px);
    margin-right: 10px;
    padding: 15px;
    border-radius: 10px;
}

.medove-submit-btn {
    color: #FFFFFF;
    font-size: 15px;
    width: 195px;
    height: 55px;
    background: #442E00;
    border-radius: 10px;
}

.medove-form-pic {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 0 60px 60px 0;
    z-index: 0;
}

.footer-bg-color{
    background-color: #442E00;
}
  
.footer{
    height: 200px;
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    max-width: 1440px;
    margin: 0 auto;
    padding-top: 50px;
}

.footer-item{
    max-width: 200px;
}

.call{
    margin-top: 15px;
}

iframe{
    border-radius: 20px;
    width: 150px;
    height: 80px;
}

.arrow-top-btn{
    width: 34px;
    height: 34px;
    border-radius: 10px;
    border: none;
    background: radial-gradient(circle, #F4C04F 0%, #EFA606 100%);
    margin-top: 100px;
    margin-left: 20px;
}

.contact-us-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 195px;
    height: 60px;
    border-radius: 10px;
    border: none;
    background: radial-gradient(circle, #F4C04F 0%, #EFA606 100%);
    color:#FFFFFF;
    font-size: 15px;
}

.contact-us-btn{
    cursor: pointer;
}

.logo-bot{
    width: 157px;
    height: 56px;
    
}

@media (max-width: 768px) {

    .carousel{
        margin-top: -100px;
    }
    .menu-items{
        display: none;
    }

    .honey-header{
        display: flex;
        flex-direction: column-reverse;
        align-items: center; 
        height: 600px;
    }   

    .honey-header-div{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-left: 0;
    }
    
    .dis-n{
        display: none;
    }

    .honey-header-h3{
        position: relative;
        width: 255px;
        height: 60px;
        text-wrap: wrap;
        font-size: 18px;
        line-height: 30px;
        border-radius: 20px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-top: -160px;
    }

    .honey-header-h3::after {
        content: '';
        display: inline-block;
        width: 38px;
        height: 38px;
        background-image: url('../../public/images/system/flower.png');
        background-size: cover;
        position: absolute;
        right: -15px;
        top: -15px;
    }

    .bee {
        background-image: url('../../public/images/system/bee-movie.png'); 
        background-size: contain;
        background-repeat: no-repeat;
        width: 56px;
        height: 36px;
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        animation: flyBee 10s infinite;
        z-index: 997;
    }
    
    @keyframes flyBee {
        0% {
            transform: translate(-500%, 40%);
        }
        50% {
            transform: translate(calc(24vw));
        }
        60% {
            transform: translate(calc(50vw - 25px), -50%);
        }
        100% {
            transform: translate(100vw, -50%);
        }
    }
    
    .about-us {
        display: flex;
        flex-direction: column;
        align-items: center; 
        height: auto;
        padding-bottom: 50px;
    }

    .about-us > * {
        width: 90%;
    }

    .about-us-img{
        width: 60%;
    }
    
    .ukranian-honey-item {
        flex-direction: column;
        align-items: center; 
        height: auto;
        margin-bottom: 50px;
    }
    
    .ukranian-honey-item > * {
        width: 90%;
    }
    .ukranian-honey-img img{
        width: 90%;
        margin: auto;
    }

    .our-bees-and-hives-item {
        flex-direction: column; 
        width: 100%;
    }
    
    .our-bees-and-hives-item > div,
    .our-bees-and-hives-item > img {
        width: 90%;
    }
    
    .our-bees-and-hives-item img {
        margin-top: 20px; 
        margin-bottom: 50px;
    }

    .our-service-container {
        flex-direction: column;
        align-items: center;
        width: 100%; 
        margin-left: 0;
        margin-right: 0;
    }
    
    .our-service-container img {
        width: 100%;
        height: auto;
        margin-bottom: 20px;
    }
    
    .our-service {
        width: 90%; 
    }

    .medove-form {
        display: block;
        position: relative;
        width: calc(370px - 40px);
        height: calc(730px - 148px);
        padding: 74px 20px;
        margin: auto;
        margin-bottom: 50px;
    }

    .medove-form-text {
        width: 328px;
        height: auto;
        margin-bottom: 20px !important;
    }

    .medove-form-left-part {
        position: relative;
    }

    .medove-inputs {
        width: calc(328px - 32px);
        height: calc(55px - 32px);
        margin-right: 0;
        margin-bottom: 10px;
    }

    .medove-submit-btn {
        width: 328px;
        height: 55px;
    }

    .medove-form-pic {
        position: absolute;
        top: auto;
        bottom: -45px;
        right: -75px;
        z-index: 0;
    }

    .footer {
        height: 450px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 30px;
    }

    .footer-item{
        width: 200px;
    }

    .arrow-top-btn {
        position: absolute;
        right: 20px;
    }
}

@media (min-width: 769px) and (max-width: 960px) {
    .medove-form { 
        width: calc(870px - 128px); 
    }
    .ml-mr-20{
        margin-left: 0;
        margin-right: 0;
    }

    .medove-submit-btn {
        text-align: center;
    }
}
